$lessBlueish: #909ab7;
$blueish: #5b6679;
$whitish: #f9f9f5;
$light-grey: #3d3d3d;
$main-grey: #262626;

#home-page {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo-container {
  margin-top: 5vw;
  padding: 0;
  position: relative;
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;

  #main-logo {
    width: 35vw;
    margin: 5vw 0 1vw 0;
    transition: 1.8s;
  }
  .name {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    color: $main-grey;
    opacity: 0;
    transition: 2s;
    cursor: default;
    h1 {
      margin: 0;
      background: none;
      color: $main-grey;
      border: none;
      font-size: 6vw;
    }
    #fullstack {
      font-size: 3vw;
      color: $main-grey;
    }
  }

  &:hover {
    #main-logo {
      transform: rotateZ(160deg);
      opacity: 0;
    }
    .name {
      opacity: 1;
    }
  }
}

#home-links {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5vw;
  width: 85vw;
  padding: 1vw;
  height: 8vw;

  a {
    font-size: 2vw;
    height: 2.5vw;
    border-bottom: 0.3vw solid $main-grey;
    text-decoration: none;
    color: inherit;
    transition: 0.8s;
  }
  a:hover {
    transform: scale(1.1);
  }
}

// iPhone 6/7/8
@media screen and (max-width: 375px) and (max-height: 667px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw;
    #home-links {
      flex-direction: column;
      height: 70vw;
      a {
        font-size: 3.5vw;
        height: fit-content;
        padding: 2vw 0;
        width: 80vw;
        border-radius: 50px;
        max-width: 100vw;
        background: $main-grey;
        color: $whitish;
        text-align: center;
      }
    }
  }
  #logo-container {
    margin-top: 1vw;
    width: 90vw;
    #fullstack {
      font-size: 4.5vw;
      color: $main-grey;
    }

    #main-logo {
      width: 80vw;
      margin: 5vw 0 1vw 0;
      transition: 1.8s;
      animation-name: logo;
      animation-duration: 4s;
    }
    .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: $main-grey;
      opacity: 0;
      transition: 2s;
      cursor: default;
      h1 {
        margin: 0;
        background: none;
        color: $main-grey;
        border: none;
        font-size: 8.5vw;
      }
      animation-name: name;
      animation-duration: 4s;
    }

    @keyframes logo {
      50% {
        transform: rotateZ(160deg);
        opacity: 0;
      }
    }
    @keyframes name {
      60% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

// iPhone 6/7/8 Plus

@media screen and (max-width: 414px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw;
    #home-links {
      flex-direction: column;
      height: 75vw;
      a {
        font-size: 4vw;
        height: fit-content;
        padding: 2vw 0;
        margin: 1.5vw;
        width: 80vw;
        border-radius: 50px;
        max-width: 100vw;
        background: $main-grey;
        color: $whitish;
        text-align: center;
      }
    }
  }
  #logo-container {
    margin-top: 1vw;
    width: 90vw;
    #fullstack {
      font-size: 4.5vw;
      color: $main-grey;
    }

    #main-logo {
      width: 80vw;
      margin: 5vw 0 1vw 0;
      transition: 1.8s;
      animation-name: logo;
      animation-duration: 4s;
    }
    .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: $main-grey;
      opacity: 0;
      transition: 2s;
      cursor: default;
      h1 {
        margin: 0;
        background: none;
        color: $main-grey;
        border: none;
        font-size: 8.5vw;
      }
      animation-name: name;
      animation-duration: 4s;
    }

    @keyframes logo {
      50% {
        transform: rotateZ(160deg);
        opacity: 0;
      }
    }
    @keyframes name {
      60% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

// iPhone XS Max
@media screen and (max-width: 414px) and (min-height: 896px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw;
    #home-links {
      flex-direction: column;
      height: 100vw;
      a {
        font-size: 4vw;
        height: fit-content;
        padding: 3vw 0;
        width: 80vw;
        border-radius: 50px;
        max-width: 100vw;
        background: $main-grey;
        color: $whitish;
        text-align: center;
      }
    }
  }
  #logo-container {
    margin-top: 15vw;
    width: 90vw;
    #fullstack {
      font-size: 4.5vw;
      color: $main-grey;
    }

    #main-logo {
      width: 80vw;
      margin: 5vw 0 1vw 0;
      transition: 1.8s;
      animation-name: logo;
      animation-duration: 4s;
    }
    .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: $main-grey;
      opacity: 0;
      transition: 2s;
      cursor: default;
      h1 {
        margin: 0;
        background: none;
        color: $main-grey;
        border: none;
        font-size: 8.5vw;
      }
      animation-name: name;
      animation-duration: 4s;
    }

    @keyframes logo {
      50% {
        transform: rotateZ(160deg);
        opacity: 0;
      }
    }
    @keyframes name {
      60% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

// iPhone X
@media screen and (max-width: 375px) and (max-height: 812px) {
  #logo-container {
    margin-top: 15vw;
  }
}

// iPad
@media screen and (max-width: 768px) and (min-height: 1000px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw;
  }
  #logo-container {
    margin-top: 1vw;
    width: 80vw;
    #fullstack {
      font-size: 4.5vw;
      color: $main-grey;
    }

    #main-logo {
      width: 70vw;
      margin: 5vw 0 1vw 0;
      transition: 1.8s;
      animation-name: logo;
      animation-duration: 4s;
    }
    .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: $main-grey;
      opacity: 0;
      transition: 2s;
      cursor: default;
      h1 {
        margin: 0;
        background: none;
        color: $main-grey;
        border: none;
        font-size: 8.5vw;
      }
      animation-name: name;
      animation-duration: 4s;
    }

    @keyframes logo {
      50% {
        transform: rotateZ(160deg);
        opacity: 0;
      }
    }
    @keyframes name {
      60% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  #home-links {
    flex-direction: column;
    height: 70vw;
    a {
      font-size: 4vw;
      height: fit-content;
      text-align: center;
      border-bottom: 1vw solid $main-grey;
    }
  }
}

// iPad Pro
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw;
  }
  #logo-container {
    margin-top: 1vw;
    width: 80vw;
    #fullstack {
      font-size: 4.5vw;
      color: $main-grey;
    }

    #main-logo {
      width: 67vw;
      margin: 5vw 0 1vw 0;
      transition: 1.8s;
      animation-name: logo;
      animation-duration: 4s;
    }
    .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: $main-grey;
      opacity: 0;
      transition: 2s;
      cursor: default;
      h1 {
        margin: 0;
        background: none;
        color: $main-grey;
        border: none;
        font-size: 8.5vw;
      }
      animation-name: name;
      animation-duration: 4s;
    }

    @keyframes logo {
      50% {
        transform: rotateZ(160deg);
        opacity: 0;
      }
    }
    @keyframes name {
      60% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  #home-links {
    flex-direction: column;
    height: 70vw;
    a {
      font-size: 4vw;
      height: fit-content;
      text-align: center;
      border-bottom: 1vw solid $main-grey;
    }
  }
}
