$lessBlueish: #909ab7;
$blueish: #5b6679;
$whitish: #f9f9f5;
$main-grey: #262626;

.education-wrapper {
  background: $main-grey;
  color: $whitish;
  text-align: center;
  margin: 2vw auto;
  width: 55vw;
  height: 60vw;
  display: flex;
  padding: 2vw;
  border-radius: 7% 0 7% 0;
  box-shadow: 0px 0px 33px -1px rgba(38, 38, 38, 0.67);
  .education-details {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2,
    h3,
    h4 {
      padding: 0;
      margin: 0.7vw;
    }
    h2 {
      font-size: 2.5vw;
    }
    h3 {
      font-size: 2vw;
    }
    h4 {
      font-size: 1.7vw;
    }
    li {
      font-size: 1.5vw;
    }
    .institution-icon {
      background-color: white;
      border: 0.2vw solid $main-grey;
      border-radius: 100%;
      height: 25vw;
      width: 25vw;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 80%;
      }
    }
  }
  .illustration {
    max-height: 100%;
    width: 40%;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
    list-style: none;
    margin: 0;
    padding: 1vw;
    li {
      padding: 1vw;
      margin: 0.2vw;
      background: $whitish;
      color: $main-grey;
    }
  }
}

// iPhone 6/7/8
@media screen and (max-width: 375px) {
  .education-wrapper {
    width: 90vw;
    height: fit-content;
    .education-details {
      width: 100%;
      height: 100%;
      h2,
      h3,
      h4 {
        margin: 1vw;
      }
      h2 {
        font-size: 5.5vw;
      }
      h3 {
        font-size: 5vw;
      }
      h4 {
        font-size: 4.7vw;
      }
      li {
        font-size: 3.5vw;
        margin: 1vw;
        padding: 1.5vw;
      }
      .institution-icon {
        width: 40vw;
        height: 40vw;
      }
    }
  }
  .illustration {
    display: none;
  }
}

// iPhone 6/7/8 Plus

@media screen and (max-width: 414px) {
  .education-wrapper {
    width: 90vw;
    height: fit-content;
    .education-details {
      width: 100%;
      height: 100%;
      h2,
      h3,
      h4 {
        margin: 1vw;
      }
      h2 {
        font-size: 5.5vw;
      }
      h3 {
        font-size: 5vw;
      }
      h4 {
        font-size: 4.7vw;
      }
      li {
        font-size: 3.5vw;
        margin: 1vw;
        padding: 1.5vw;
      }
      .institution-icon {
        width: 40vw;
        height: 40vw;
      }
    }
  }
  .illustration {
    display: none;
  }
}

// iPad
@media screen and (max-width: 768px) and (min-height: 1000px) {
  .education-wrapper {
    width: 90vw;
    height: fit-content;
    .education-details {
      //     width: 100%;
      //     height: 100%;
      h2,
      h3,
      h4 {
        margin: 1vw;
      }
      h2 {
        font-size: 3.5vw;
      }
      h3 {
        font-size: 3vw;
      }
      h4 {
        font-size: 2.7vw;
      }
      li {
        font-size: 2vw;
        margin: 1vw;
        padding: 1.5vw;
      }
    }
  }
}
