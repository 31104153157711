$lessBlueish: #909ab7;
$blueish: #5b6679;
$whitish: #f9f9f5;
$light-grey: #3d3d3d;
$main-grey: #262626;

.about-mazen-content {
  background: $main-grey;
  color: $whitish;
  padding: 1vw;
  border: 0.3vw solid $main-grey;
  border-radius: 60px 0 60px 0;
  display: grid;
  grid-template-columns: 65% 35%;
  width: 75vw;
  margin: 0 auto;
  text-align: justify;
  p {
    font-size: 1.8vw;
    margin: 0;
    margin-bottom: 1vw;
  }
  a {
    text-decoration: none;
    color: $lessBlueish;
    transition: 0.5s;
    font-weight: bold;
    height: fit-content;
    width: fit-content;
    &:hover {
      color: blue;
    }
  }
  .about-page-text-wrapper {
    grid-column: 1;
    grid-row: 1 span 2;
    padding: 3vw;
    line-height: 2.5vw;
  }
  .about-page-image-wrapper {
    padding: 3vw;
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .img-div {
      width: 18vw;
      height: 18vw;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 140%;
      }
    }
  }
}

// iPhone 6/7/8
@media screen and (max-width: 375px) {
  .about-mazen-content {
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    width: 80vw;
    padding: 5vw;

    p {
      font-size: 4vw;
      line-height: 6.5vw;
      margin: 0;
      margin-bottom: 1vw;
    }
    a {
      text-decoration: none;
      color: $lessBlueish;
      transition: 0.5s;
      font-weight: bold;
      height: fit-content;
      width: fit-content;
      &:hover {
        color: blue;
      }
    }
    .about-page-image-wrapper {
      display: flex;
      flex-direction: row;
      .img-div {
        width: 20vw;
        height: 20vw;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 140%;
        }
      }
    }
  }
}

// iPhone 6/7/8 Plus

@media screen and (max-width: 414px) {
  .about-mazen-content {
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    width: 80vw;
    padding: 5vw;

    p {
      font-size: 4vw;
      line-height: 6.5vw;
      margin: 0;
      margin-bottom: 1vw;
    }
    a {
      text-decoration: none;
      color: $lessBlueish;
      transition: 0.5s;
      font-weight: bold;
      height: fit-content;
      width: fit-content;
      &:hover {
        color: blue;
      }
    }
    .about-page-image-wrapper {
      display: flex;
      flex-direction: row;
      .img-div {
        width: 20vw;
        height: 20vw;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 140%;
        }
      }
    }
  }
}

// iPad

@media screen and (max-width: 768px) and (min-height: 1000px) {
  .about-mazen-content {
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    width: 80vw;
    padding: 5vw;

    p {
      font-size: 4vw;
      line-height: 6.5vw;
      margin: 0;
      margin-bottom: 1vw;
    }
    a {
      text-decoration: none;
      color: $lessBlueish;
      transition: 0.5s;
      font-weight: bold;
      height: fit-content;
      width: fit-content;
      &:hover {
        color: blue;
      }
    }
    .about-page-image-wrapper {
      display: flex;
      flex-direction: row;
      .img-div {
        width: 20vw;
        height: 20vw;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 140%;
        }
      }
    }
  }
}
