@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

$lessBlueish: #909ab7;
$blueish: #5b6679;
$whitish: #f9f9f5;
$light-grey: #3d3d3d;
$main-grey: #262626;

html,
body,
#root {
  background: $whitish;
  height: fit-content;
  max-width: 100%;
}

#root {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  background: $whitish;
  margin-bottom: 10vw;
}

h1 {
  margin: 2vw auto;
  text-align: center;
  width: fit-content;
  padding: 3vw 5vw;
  background: $main-grey;
  color: white;
  border-radius: 10px;
  font-size: 2vw;
}

#main-nav {
  position: fixed;
  right: 1.5vw;
  top: 5vw;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  height: 20vw;
  width: 7vw;
  background-color: $main-grey;

  border-radius: 100px;
  img {
    transition: 0.6s;
  }
  img:hover {
    transform: scale(1.3);
  }
}

.nav-icon {
  width: 4vw;
  color: white;
}

// iPhone XS Max
@media screen and (max-width: 414px) and (max-height: 896px) {
  h1 {
    font-size: 4vw;
  }
  html,
  body,
  #root {
    margin-bottom: 15vw;
    height: fit-content;
  }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0;
  }
  .nav-icon {
    width: 10vw;
  }
}

// iPhone 6/7/8
@media screen and (max-width: 375px) {
  h1 {
    font-size: 4vw;
  }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0;
  }
  .nav-icon {
    width: 10vw;
  }
}

// iPhone 6/7/8 Plus
@media screen and (max-width: 414px) {
  h1 {
    font-size: 4vw;
  }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0;
  }
  .nav-icon {
    width: 10vw;
  }
}

// iPhone X

@media screen and (max-width: 375px) and (max-height: 812px) {
  h1 {
    font-size: 4vw;
  }
  html,
  body,
  #root {
    margin-bottom: 15vw;
    height: fit-content;
  }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0;
  }
  .nav-icon {
    width: 10vw;
  }
}

// iPad
@media screen and (max-width: 768px) and (min-height: 1000px) {
  html,
  body,
  #root {
    margin-bottom: 10vw;
    height: fit-content;
  }
  h1 {
    font-size: 4vw;
  }
  #main-nav {
    height: 35vw;
    width: 12vw;
  }
  .nav-icon {
    width: 7vw;
  }
}

// iPad Pro
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  html,
  body,
  #root {
    margin-bottom: 10vw;
    height: fit-content;
  }
  h1 {
    font-size: 4vw;
  }
  #main-nav {
    height: 35vw;
    width: 12vw;
  }
  .nav-icon {
    width: 7vw;
  }
}
