$lessBlueish: #909ab7;
$blueish: #5b6679;
$whitish: #f9f9f5;
$light-grey: #3d3d3d;
$main-grey: #262626;

#work-exprience-container {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: $main-grey;
}

.diplomat-container {
  padding: 3vw;
  background: $main-grey;
  width: 75%;
  margin: auto;
  margin-bottom: 5vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 100px 0 100px 0;
}

.section-title {
  width: 70%;
  margin: 0 auto;
  background: $whitish;
  color: $main-grey;
  padding: 2vw;
  font-size: 1.8vw;
  text-align: center;
}

.section-header {
  width: 100%;
  font-size: 1.8vw;
  margin: 2vw auto;
  text-align: center;
  color: $main-grey;
  background: $whitish;
  padding: 0.7vw 0;
  border-radius: 60px;
}

.work-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  color: $whitish;
  .place-of-work {
    text-align: center;
    padding: 3vw 0;
    width: 75vw;
    font-size: 2.5vw;
    margin: 5vw auto;
  }

  .job-titles {
    margin-left: 0.5vw;
    font-style: italic;
    font-weight: lighter;
  }
  .jb-header {
    font-size: 3vw;
    width: 100%;
    text-align: center;
    margin: 1vw;
    color: $whitish;
  }
  .jb-title {
    color: $whitish;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5vw;
    height: 5vw;
  }
}

.ul-tasks {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    max-width: fit-content;
    text-align: center;
    background: $whitish;
    color: $main-grey;
    padding: 0 2vw;
    margin: 1vw auto;
    h4 {
      text-align: left;
      width: 100%;
      font-size: 1.2vw;
    }
  }
}

.highlights {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 2vw;
    padding: 3vw;
    margin: 0;
    border: none;
  }

  img {
    border-radius: 100%;
    width: 6vw;
    height: 6vw;
    padding: 0.2vw;
    border: 2px solid #262626;
  }
  .speech-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25vw;
    font-size: 1vw;
    img {
      background: $whitish;
    }
    a {
      text-decoration: none;
      color: $whitish;
      transition: 0.5s;
      &:hover {
        color: $blueish;
        cursor: pointer;
      }
    }
  }
  .speech-description {
    text-align: center;
  }
}

.highlights-title {
  font-size: 3vw;
  width: 100%;
  text-align: center;
}
.container,
.misc-container {
  width: 80vw;
  margin: auto;
  color: $whitish;
}

.misc-container {
  background: $main-grey;
  padding: 3vw 0;
  margin-bottom: 10vw;
  border-radius: 100px 0 100px 0;
  border: 0.3vw solid #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  .job-title-div {
    background: $whitish;
    color: $main-grey;
    border-radius: 80px;
    margin-top: 1vw;
    padding: 0.5vw 0;
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    h2,
    h3,
    span {
      text-align: center;
      max-width: 23vw;
    }
    h2 {
      font-size: 1.5vw;
    }
    h3 {
      font-size: 1.4vw;
    }
    span {
      font-size: 1.4vw;
      font-weight: bold;
    }
  }
  .ul-tasks-2 {
    width: 80%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      width: fit-content;
      text-align: center;
      background: $whitish;
      color: $main-grey;
      font-size: 1.2vw;
      font-weight: bold;
      padding: 1.5vw;
      margin: 1vw;
    }
  }
}

// iPhone 6/7/8
@media screen and (max-width: 375px) {
  .diplomat-container,
  .misc-container {
    border-radius: 20px 0 20px 0;
    width: 90%;
    max-width: 90%;
    margin: 1vw auto;
    padding: 3vw;
  }
  h2.section-title {
    font-size: 2.5vw;
  }
  h2.section-header {
    font-size: 2.5vw;
  }
  .diplomat-container {
    .work-div {
      h2.jb-title {
        font-size: 2vw;
      }
    }
    li {
      h4 {
        font-size: 1.5vw;
      }
    }
  }
}

// iPhone 6/7/8 Plus
@media screen and (max-width: 414px) {
  .diplomat-container,
  .misc-container {
    border-radius: 20px 0 20px 0;
    width: 90%;
    max-width: 90%;
    margin: 1vw auto;
    padding: 3vw;
  }
  h2.section-title {
    font-size: 2.5vw;
  }
  h2.section-header {
    font-size: 2.5vw;
  }
  .diplomat-container {
    .work-div {
      h2.jb-title {
        font-size: 2vw;
      }
    }
    li {
      h4 {
        font-size: 1.5vw;
      }
    }
  }
}

// iPad

@media screen and (max-width: 768px) and (min-height: 1000px) {
  .diplomat-container,
  .misc-container {
    border-radius: 20px 0 20px 0;
    width: 65%;
    max-width: 65%;
    margin: 1vw auto;
    padding: 3vw;
  }
  h2.section-title {
    font-size: 2.5vw;
  }
  h2.section-header {
    font-size: 2.5vw;
  }
  .diplomat-container {
    .work-div {
      h2.jb-title {
        font-size: 2vw;
      }
    }
    li {
      h4 {
        font-size: 1.5vw;
      }
    }
  }
}
