@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
#home-page {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#logo-container {
  margin-top: 5vw;
  padding: 0;
  position: relative;
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center; }
  #logo-container #main-logo {
    width: 35vw;
    margin: 5vw 0 1vw 0;
    -webkit-transition: 1.8s;
    transition: 1.8s; }
  #logo-container .name {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    color: #262626;
    opacity: 0;
    -webkit-transition: 2s;
    transition: 2s;
    cursor: default; }
    #logo-container .name h1 {
      margin: 0;
      background: none;
      color: #262626;
      border: none;
      font-size: 6vw; }
    #logo-container .name #fullstack {
      font-size: 3vw;
      color: #262626; }
  #logo-container:hover #main-logo {
    -webkit-transform: rotateZ(160deg);
            transform: rotateZ(160deg);
    opacity: 0; }
  #logo-container:hover .name {
    opacity: 1; }

#home-links {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5vw;
  width: 85vw;
  padding: 1vw;
  height: 8vw; }
  #home-links a {
    font-size: 2vw;
    height: 2.5vw;
    border-bottom: 0.3vw solid #262626;
    text-decoration: none;
    color: inherit;
    -webkit-transition: 0.8s;
    transition: 0.8s; }
  #home-links a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

@media screen and (max-width: 375px) and (max-height: 667px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw; }
    #home-page #home-links {
      flex-direction: column;
      height: 70vw; }
      #home-page #home-links a {
        font-size: 3.5vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2vw 0;
        width: 80vw;
        border-radius: 50px;
        max-width: 100vw;
        background: #262626;
        color: #f9f9f5;
        text-align: center; }
  #logo-container {
    margin-top: 1vw;
    width: 90vw; }
    #logo-container #fullstack {
      font-size: 4.5vw;
      color: #262626; }
    #logo-container #main-logo {
      width: 80vw;
      margin: 5vw 0 1vw 0;
      -webkit-transition: 1.8s;
      transition: 1.8s;
      -webkit-animation-name: logo;
              animation-name: logo;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
    #logo-container .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: #262626;
      opacity: 0;
      -webkit-transition: 2s;
      transition: 2s;
      cursor: default;
      -webkit-animation-name: name;
              animation-name: name;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      #logo-container .name h1 {
        margin: 0;
        background: none;
        color: #262626;
        border: none;
        font-size: 8.5vw; }
  @-webkit-keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @-webkit-keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } } }

@media screen and (max-width: 414px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw; }
    #home-page #home-links {
      flex-direction: column;
      height: 75vw; }
      #home-page #home-links a {
        font-size: 4vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2vw 0;
        margin: 1.5vw;
        width: 80vw;
        border-radius: 50px;
        max-width: 100vw;
        background: #262626;
        color: #f9f9f5;
        text-align: center; }
  #logo-container {
    margin-top: 1vw;
    width: 90vw; }
    #logo-container #fullstack {
      font-size: 4.5vw;
      color: #262626; }
    #logo-container #main-logo {
      width: 80vw;
      margin: 5vw 0 1vw 0;
      -webkit-transition: 1.8s;
      transition: 1.8s;
      -webkit-animation-name: logo;
              animation-name: logo;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
    #logo-container .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: #262626;
      opacity: 0;
      -webkit-transition: 2s;
      transition: 2s;
      cursor: default;
      -webkit-animation-name: name;
              animation-name: name;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      #logo-container .name h1 {
        margin: 0;
        background: none;
        color: #262626;
        border: none;
        font-size: 8.5vw; }
  @-webkit-keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @-webkit-keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } } }

@media screen and (max-width: 414px) and (min-height: 896px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw; }
    #home-page #home-links {
      flex-direction: column;
      height: 100vw; }
      #home-page #home-links a {
        font-size: 4vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 3vw 0;
        width: 80vw;
        border-radius: 50px;
        max-width: 100vw;
        background: #262626;
        color: #f9f9f5;
        text-align: center; }
  #logo-container {
    margin-top: 15vw;
    width: 90vw; }
    #logo-container #fullstack {
      font-size: 4.5vw;
      color: #262626; }
    #logo-container #main-logo {
      width: 80vw;
      margin: 5vw 0 1vw 0;
      -webkit-transition: 1.8s;
      transition: 1.8s;
      -webkit-animation-name: logo;
              animation-name: logo;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
    #logo-container .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: #262626;
      opacity: 0;
      -webkit-transition: 2s;
      transition: 2s;
      cursor: default;
      -webkit-animation-name: name;
              animation-name: name;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      #logo-container .name h1 {
        margin: 0;
        background: none;
        color: #262626;
        border: none;
        font-size: 8.5vw; }
  @-webkit-keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @-webkit-keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } } }

@media screen and (max-width: 375px) and (max-height: 812px) {
  #logo-container {
    margin-top: 15vw; } }

@media screen and (max-width: 768px) and (min-height: 1000px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw; }
  #logo-container {
    margin-top: 1vw;
    width: 80vw; }
    #logo-container #fullstack {
      font-size: 4.5vw;
      color: #262626; }
    #logo-container #main-logo {
      width: 70vw;
      margin: 5vw 0 1vw 0;
      -webkit-transition: 1.8s;
      transition: 1.8s;
      -webkit-animation-name: logo;
              animation-name: logo;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
    #logo-container .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: #262626;
      opacity: 0;
      -webkit-transition: 2s;
      transition: 2s;
      cursor: default;
      -webkit-animation-name: name;
              animation-name: name;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      #logo-container .name h1 {
        margin: 0;
        background: none;
        color: #262626;
        border: none;
        font-size: 8.5vw; }
  @-webkit-keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @-webkit-keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  #home-links {
    flex-direction: column;
    height: 70vw; }
    #home-links a {
      font-size: 4vw;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      text-align: center;
      border-bottom: 1vw solid #262626; } }

@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #home-page {
    justify-content: flex-start;
    max-width: 100vw; }
  #logo-container {
    margin-top: 1vw;
    width: 80vw; }
    #logo-container #fullstack {
      font-size: 4.5vw;
      color: #262626; }
    #logo-container #main-logo {
      width: 67vw;
      margin: 5vw 0 1vw 0;
      -webkit-transition: 1.8s;
      transition: 1.8s;
      -webkit-animation-name: logo;
              animation-name: logo;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
    #logo-container .name {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      color: #262626;
      opacity: 0;
      -webkit-transition: 2s;
      transition: 2s;
      cursor: default;
      -webkit-animation-name: name;
              animation-name: name;
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      #logo-container .name h1 {
        margin: 0;
        background: none;
        color: #262626;
        border: none;
        font-size: 8.5vw; }
  @-webkit-keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @keyframes logo {
    50% {
      -webkit-transform: rotateZ(160deg);
              transform: rotateZ(160deg);
      opacity: 0; } }
  @-webkit-keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes name {
    60% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  #home-links {
    flex-direction: column;
    height: 70vw; }
    #home-links a {
      font-size: 4vw;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      text-align: center;
      border-bottom: 1vw solid #262626; } }

#work-exprience-container {
  width: 100%;
  max-width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #262626; }

.diplomat-container {
  padding: 3vw;
  background: #262626;
  width: 75%;
  margin: auto;
  margin-bottom: 5vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 100px 0 100px 0; }

.section-title {
  width: 70%;
  margin: 0 auto;
  background: #f9f9f5;
  color: #262626;
  padding: 2vw;
  font-size: 1.8vw;
  text-align: center; }

.section-header {
  width: 100%;
  font-size: 1.8vw;
  margin: 2vw auto;
  text-align: center;
  color: #262626;
  background: #f9f9f5;
  padding: 0.7vw 0;
  border-radius: 60px; }

.work-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #f9f9f5; }
  .work-div .place-of-work {
    text-align: center;
    padding: 3vw 0;
    width: 75vw;
    font-size: 2.5vw;
    margin: 5vw auto; }
  .work-div .job-titles {
    margin-left: 0.5vw;
    font-style: italic;
    font-weight: lighter; }
  .work-div .jb-header {
    font-size: 3vw;
    width: 100%;
    text-align: center;
    margin: 1vw;
    color: #f9f9f5; }
  .work-div .jb-title {
    color: #f9f9f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5vw;
    height: 5vw; }

.ul-tasks {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .ul-tasks li {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    text-align: center;
    background: #f9f9f5;
    color: #262626;
    padding: 0 2vw;
    margin: 1vw auto; }
    .ul-tasks li h4 {
      text-align: left;
      width: 100%;
      font-size: 1.2vw; }

.highlights {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .highlights h1 {
    width: 100%;
    text-align: center;
    font-size: 2vw;
    padding: 3vw;
    margin: 0;
    border: none; }
  .highlights img {
    border-radius: 100%;
    width: 6vw;
    height: 6vw;
    padding: 0.2vw;
    border: 2px solid #262626; }
  .highlights .speech-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25vw;
    font-size: 1vw; }
    .highlights .speech-div img {
      background: #f9f9f5; }
    .highlights .speech-div a {
      text-decoration: none;
      color: #f9f9f5;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
      .highlights .speech-div a:hover {
        color: #5b6679;
        cursor: pointer; }
  .highlights .speech-description {
    text-align: center; }

.highlights-title {
  font-size: 3vw;
  width: 100%;
  text-align: center; }

.container,
.misc-container {
  width: 80vw;
  margin: auto;
  color: #f9f9f5; }

.misc-container {
  background: #262626;
  padding: 3vw 0;
  margin-bottom: 10vw;
  border-radius: 100px 0 100px 0;
  border: 0.3vw solid #262626;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .misc-container .job-title-div {
    background: #f9f9f5;
    color: #262626;
    border-radius: 80px;
    margin-top: 1vw;
    padding: 0.5vw 0;
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    align-items: center; }
    .misc-container .job-title-div h2,
    .misc-container .job-title-div h3,
    .misc-container .job-title-div span {
      text-align: center;
      max-width: 23vw; }
    .misc-container .job-title-div h2 {
      font-size: 1.5vw; }
    .misc-container .job-title-div h3 {
      font-size: 1.4vw; }
    .misc-container .job-title-div span {
      font-size: 1.4vw;
      font-weight: bold; }
  .misc-container .ul-tasks-2 {
    width: 80%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center; }
    .misc-container .ul-tasks-2 li {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      text-align: center;
      background: #f9f9f5;
      color: #262626;
      font-size: 1.2vw;
      font-weight: bold;
      padding: 1.5vw;
      margin: 1vw; }

@media screen and (max-width: 375px) {
  .diplomat-container,
  .misc-container {
    border-radius: 20px 0 20px 0;
    width: 90%;
    max-width: 90%;
    margin: 1vw auto;
    padding: 3vw; }
  h2.section-title {
    font-size: 2.5vw; }
  h2.section-header {
    font-size: 2.5vw; }
  .diplomat-container .work-div h2.jb-title {
    font-size: 2vw; }
  .diplomat-container li h4 {
    font-size: 1.5vw; } }

@media screen and (max-width: 414px) {
  .diplomat-container,
  .misc-container {
    border-radius: 20px 0 20px 0;
    width: 90%;
    max-width: 90%;
    margin: 1vw auto;
    padding: 3vw; }
  h2.section-title {
    font-size: 2.5vw; }
  h2.section-header {
    font-size: 2.5vw; }
  .diplomat-container .work-div h2.jb-title {
    font-size: 2vw; }
  .diplomat-container li h4 {
    font-size: 1.5vw; } }

@media screen and (max-width: 768px) and (min-height: 1000px) {
  .diplomat-container,
  .misc-container {
    border-radius: 20px 0 20px 0;
    width: 65%;
    max-width: 65%;
    margin: 1vw auto;
    padding: 3vw; }
  h2.section-title {
    font-size: 2.5vw; }
  h2.section-header {
    font-size: 2.5vw; }
  .diplomat-container .work-div h2.jb-title {
    font-size: 2vw; }
  .diplomat-container li h4 {
    font-size: 1.5vw; } }

.education-wrapper {
  background: #262626;
  color: #f9f9f5;
  text-align: center;
  margin: 2vw auto;
  width: 55vw;
  height: 60vw;
  display: flex;
  padding: 2vw;
  border-radius: 7% 0 7% 0;
  box-shadow: 0px 0px 33px -1px rgba(38, 38, 38, 0.67); }
  .education-wrapper .education-details {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .education-wrapper .education-details h2,
    .education-wrapper .education-details h3,
    .education-wrapper .education-details h4 {
      padding: 0;
      margin: 0.7vw; }
    .education-wrapper .education-details h2 {
      font-size: 2.5vw; }
    .education-wrapper .education-details h3 {
      font-size: 2vw; }
    .education-wrapper .education-details h4 {
      font-size: 1.7vw; }
    .education-wrapper .education-details li {
      font-size: 1.5vw; }
    .education-wrapper .education-details .institution-icon {
      background-color: white;
      border: 0.2vw solid #262626;
      border-radius: 100%;
      height: 25vw;
      width: 25vw;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
      .education-wrapper .education-details .institution-icon img {
        width: 80%; }
  .education-wrapper .illustration {
    max-height: 100%;
    width: 40%; }
  .education-wrapper ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    list-style: none;
    margin: 0;
    padding: 1vw; }
    .education-wrapper ul li {
      padding: 1vw;
      margin: 0.2vw;
      background: #f9f9f5;
      color: #262626; }

@media screen and (max-width: 375px) {
  .education-wrapper {
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .education-wrapper .education-details {
      width: 100%;
      height: 100%; }
      .education-wrapper .education-details h2,
      .education-wrapper .education-details h3,
      .education-wrapper .education-details h4 {
        margin: 1vw; }
      .education-wrapper .education-details h2 {
        font-size: 5.5vw; }
      .education-wrapper .education-details h3 {
        font-size: 5vw; }
      .education-wrapper .education-details h4 {
        font-size: 4.7vw; }
      .education-wrapper .education-details li {
        font-size: 3.5vw;
        margin: 1vw;
        padding: 1.5vw; }
      .education-wrapper .education-details .institution-icon {
        width: 40vw;
        height: 40vw; }
  .illustration {
    display: none; } }

@media screen and (max-width: 414px) {
  .education-wrapper {
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .education-wrapper .education-details {
      width: 100%;
      height: 100%; }
      .education-wrapper .education-details h2,
      .education-wrapper .education-details h3,
      .education-wrapper .education-details h4 {
        margin: 1vw; }
      .education-wrapper .education-details h2 {
        font-size: 5.5vw; }
      .education-wrapper .education-details h3 {
        font-size: 5vw; }
      .education-wrapper .education-details h4 {
        font-size: 4.7vw; }
      .education-wrapper .education-details li {
        font-size: 3.5vw;
        margin: 1vw;
        padding: 1.5vw; }
      .education-wrapper .education-details .institution-icon {
        width: 40vw;
        height: 40vw; }
  .illustration {
    display: none; } }

@media screen and (max-width: 768px) and (min-height: 1000px) {
  .education-wrapper {
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .education-wrapper .education-details h2,
    .education-wrapper .education-details h3,
    .education-wrapper .education-details h4 {
      margin: 1vw; }
    .education-wrapper .education-details h2 {
      font-size: 3.5vw; }
    .education-wrapper .education-details h3 {
      font-size: 3vw; }
    .education-wrapper .education-details h4 {
      font-size: 2.7vw; }
    .education-wrapper .education-details li {
      font-size: 2vw;
      margin: 1vw;
      padding: 1.5vw; } }

.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.project-wrapper {
  background: #262626;
  color: #f9f9f5;
  margin: 1.5vw;
  width: 35vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: 60vw;
  padding: 2vw;
  border-radius: 60px 0 60px 0;
  box-shadow: 0px 0px 33px -1px rgba(38, 38, 38, 0.67); }
  .project-wrapper h2 {
    text-align: center;
    font-size: 3vw; }
  .project-wrapper .icon-wrapper {
    display: flex;
    justify-content: center;
    padding: 1vw; }
    .project-wrapper .icon-wrapper a.project-icon {
      margin: 0 1vw;
      border-radius: 100%;
      background: white;
      overflow: hidden;
      width: 7vw;
      height: 7vw;
      font-size: 1.5vw;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      -webkit-transition: 0.7s;
      transition: 0.7s;
      box-shadow: 0px 0px 33px -1px rgba(38, 38, 38, 0.67); }
      .project-wrapper .icon-wrapper a.project-icon img {
        width: 120%; }
      .project-wrapper .icon-wrapper a.project-icon:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
    .project-wrapper .icon-wrapper .demo {
      background: #f9f9f5;
      color: #262626; }
  .project-wrapper p {
    font-size: 1.5vw; }
  .project-wrapper ul {
    font-size: 2.3vw;
    list-style: circle;
    display: flex;
    flex-direction: column;
    padding: 0 1vw;
    margin: 0; }
    .project-wrapper ul li {
      font-size: 1.3vw;
      margin: 1vw 0; }

@media screen and (max-width: 375px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .project-wrapper {
    width: 90vw;
    height: 70vh; }
    .project-wrapper h2 {
      font-size: 4vw; }
    .project-wrapper p {
      font-size: 3vw;
      padding: 0 4vw; }
    .project-wrapper ul {
      max-width: 100%;
      font-size: 3vw;
      list-style: circle;
      display: flex;
      flex-direction: column;
      padding: 0 4vw;
      margin: 0; }
      .project-wrapper ul li {
        font-size: 2.7vw;
        margin: 1vw 0; }
    .project-wrapper .icon-wrapper a.project-icon {
      width: 12vw;
      height: 12vw;
      font-size: 3.5vw; } }

@media screen and (max-width: 414px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .project-wrapper {
    width: 90vw;
    height: 70vh; }
    .project-wrapper h2 {
      font-size: 4vw; }
    .project-wrapper p {
      font-size: 3vw;
      padding: 0 4vw; }
    .project-wrapper ul {
      max-width: 100%;
      font-size: 3vw;
      list-style: circle;
      display: flex;
      flex-direction: column;
      padding: 0 4vw;
      margin: 0; }
      .project-wrapper ul li {
        font-size: 2.7vw;
        margin: 1vw 0; }
    .project-wrapper .icon-wrapper a.project-icon {
      width: 12vw;
      height: 12vw;
      font-size: 3.5vw; } }

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .project-wrapper {
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 5vw; }
    .project-wrapper h2 {
      font-size: 4vw; }
    .project-wrapper p {
      font-size: 3vw;
      padding: 0 4vw; }
    .project-wrapper ul {
      max-width: 100%;
      font-size: 3vw;
      list-style: circle;
      display: flex;
      flex-direction: column;
      padding: 0 4vw;
      margin: 0; }
      .project-wrapper ul li {
        font-size: 2.7vw;
        margin: 1vw 0; }
    .project-wrapper .icon-wrapper a.project-icon {
      width: 12vw;
      height: 12vw;
      font-size: 3.5vw; } }

.about-mazen-content {
  background: #262626;
  color: #f9f9f5;
  padding: 1vw;
  border: 0.3vw solid #262626;
  border-radius: 60px 0 60px 0;
  display: grid;
  grid-template-columns: 65% 35%;
  width: 75vw;
  margin: 0 auto;
  text-align: justify; }
  .about-mazen-content p {
    font-size: 1.8vw;
    margin: 0;
    margin-bottom: 1vw; }
  .about-mazen-content a {
    text-decoration: none;
    color: #909ab7;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: bold;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .about-mazen-content a:hover {
      color: blue; }
  .about-mazen-content .about-page-text-wrapper {
    grid-column: 1;
    grid-row: 1 span 2;
    padding: 3vw;
    line-height: 2.5vw; }
  .about-mazen-content .about-page-image-wrapper {
    padding: 3vw;
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .about-mazen-content .about-page-image-wrapper .img-div {
      width: 18vw;
      height: 18vw;
      border-radius: 100%;
      overflow: hidden; }
      .about-mazen-content .about-page-image-wrapper .img-div img {
        width: 140%; }

@media screen and (max-width: 375px) {
  .about-mazen-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    width: 80vw;
    padding: 5vw; }
    .about-mazen-content p {
      font-size: 4vw;
      line-height: 6.5vw;
      margin: 0;
      margin-bottom: 1vw; }
    .about-mazen-content a {
      text-decoration: none;
      color: #909ab7;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-weight: bold;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .about-mazen-content a:hover {
        color: blue; }
    .about-mazen-content .about-page-image-wrapper {
      display: flex;
      flex-direction: row; }
      .about-mazen-content .about-page-image-wrapper .img-div {
        width: 20vw;
        height: 20vw;
        border-radius: 100%;
        overflow: hidden; }
        .about-mazen-content .about-page-image-wrapper .img-div img {
          width: 140%; } }

@media screen and (max-width: 414px) {
  .about-mazen-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    width: 80vw;
    padding: 5vw; }
    .about-mazen-content p {
      font-size: 4vw;
      line-height: 6.5vw;
      margin: 0;
      margin-bottom: 1vw; }
    .about-mazen-content a {
      text-decoration: none;
      color: #909ab7;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-weight: bold;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .about-mazen-content a:hover {
        color: blue; }
    .about-mazen-content .about-page-image-wrapper {
      display: flex;
      flex-direction: row; }
      .about-mazen-content .about-page-image-wrapper .img-div {
        width: 20vw;
        height: 20vw;
        border-radius: 100%;
        overflow: hidden; }
        .about-mazen-content .about-page-image-wrapper .img-div img {
          width: 140%; } }

@media screen and (max-width: 768px) and (min-height: 1000px) {
  .about-mazen-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    width: 80vw;
    padding: 5vw; }
    .about-mazen-content p {
      font-size: 4vw;
      line-height: 6.5vw;
      margin: 0;
      margin-bottom: 1vw; }
    .about-mazen-content a {
      text-decoration: none;
      color: #909ab7;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-weight: bold;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .about-mazen-content a:hover {
        color: blue; }
    .about-mazen-content .about-page-image-wrapper {
      display: flex;
      flex-direction: row; }
      .about-mazen-content .about-page-image-wrapper .img-div {
        width: 20vw;
        height: 20vw;
        border-radius: 100%;
        overflow: hidden; }
        .about-mazen-content .about-page-image-wrapper .img-div img {
          width: 140%; } }

html,
body,
#root {
  background: #f9f9f5;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 100%; }

#root {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  background: #f9f9f5;
  margin-bottom: 10vw; }

h1 {
  margin: 2vw auto;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3vw 5vw;
  background: #262626;
  color: white;
  border-radius: 10px;
  font-size: 2vw; }

#main-nav {
  position: fixed;
  right: 1.5vw;
  top: 5vw;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  height: 20vw;
  width: 7vw;
  background-color: #262626;
  border-radius: 100px; }
  #main-nav img {
    -webkit-transition: 0.6s;
    transition: 0.6s; }
  #main-nav img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }

.nav-icon {
  width: 4vw;
  color: white; }

@media screen and (max-width: 414px) and (max-height: 896px) {
  h1 {
    font-size: 4vw; }
  html,
  body,
  #root {
    margin-bottom: 15vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0; }
  .nav-icon {
    width: 10vw; } }

@media screen and (max-width: 375px) {
  h1 {
    font-size: 4vw; }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0; }
  .nav-icon {
    width: 10vw; } }

@media screen and (max-width: 414px) {
  h1 {
    font-size: 4vw; }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0; }
  .nav-icon {
    width: 10vw; } }

@media screen and (max-width: 375px) and (max-height: 812px) {
  h1 {
    font-size: 4vw; }
  html,
  body,
  #root {
    margin-bottom: 15vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  #main-nav {
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    border-radius: 0; }
  .nav-icon {
    width: 10vw; } }

@media screen and (max-width: 768px) and (min-height: 1000px) {
  html,
  body,
  #root {
    margin-bottom: 10vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  h1 {
    font-size: 4vw; }
  #main-nav {
    height: 35vw;
    width: 12vw; }
  .nav-icon {
    width: 7vw; } }

@media screen and (max-width: 1024px) and (min-height: 1300px) {
  html,
  body,
  #root {
    margin-bottom: 10vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  h1 {
    font-size: 4vw; }
  #main-nav {
    height: 35vw;
    width: 12vw; }
  .nav-icon {
    width: 7vw; } }

